import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Container } from '@ui/Container';

import { ReflioClient } from '@integrations/reflio/client';
import {
  parseReflioAffiliateReferralCode,
  checkReflioCookie,
  setReflioCookie,
} from '@integrations/reflio/server';

import { Footer } from '@components/layout/Footer';
import Header from '@components/layout/Header';
import SEOHead from '@components/SEOHead';

import { Hero } from '@components/HomePage/Hero';
import { Details } from '@components/HomePage/Details';
import { AsSeenIn } from '@components/HomePage/AsSeenIn';
import { FAQ } from '@components/HomePage/FAQ';
import { Privacy } from '@components/HomePage/Privacy';
import { ReviewsCardSection } from '@components/ReviewsCardSection';
import { reviews } from '@data/reviews';
import { PhotoGen } from '@components/HomePage/PhotoGen';
import { CreateOwnGf } from '@components/HomePage/CreateOwnGf';
import {
  parseGoogleAdsQueryParams,
  setGoogleAdsCookies,
} from '@integrations/google_ads/server';
import { DualAppBanner } from '@components/SweetWaifu/DualAppBanner';

export default function HomePage({ reviewsColLeft, reviewsColRight }) {
  const { t: tt } = useTranslation('home');

  return (
    <>
      <SEOHead
        title={tt('seo.title')}
        description={tt('seo.description')}
        type="website"
        keywords={tt('seo.keywords')}
        url="https://www.aigirlfriend.wtf/"
        imageUrl="https://www.aigirlfriend.wtf/web-banner-min.jpg"
      />

      <Header />

      <Container as="main" className="flex flex-col gap-[120px]">
        <div
          className={clsx(
            'min-h-screen',
            'flex flex-col justify-between gap-[120px] lg:gap-0',
          )}
        >
          <div
            className={clsx(
              'max-[1439px]:min-h-screen',
              'pt-[105px] md:pt-[65px]',
              'flex-1 flex flex-col justify-center',
            )}
          >
            <Hero />
          </div>

          <div className="min-[1440px]:pb-[32px]">
            <AsSeenIn />
          </div>
        </div>
        <DualAppBanner />
        <Details />
        <CreateOwnGf />
        <PhotoGen />
        <Privacy />
        <ReviewsCardSection
          reviewsColRight={reviewsColRight}
          reviewsColLeft={reviewsColLeft}
        />
        <FAQ />
        <DualAppBanner />
      </Container>

      <Footer />
    </>
  );
}

/**
 * @param {import('next').GetServerSidePropsContext} context
 *
 * @returns {import('next').GetServerSidePropsResult}
 */
export async function getServerSideProps({ locale, req, res, query }) {
  const hasReflioCookieSet = checkReflioCookie(req);
  const reflioReferralCode = parseReflioAffiliateReferralCode(query);

  const maybeGoogleAdsParams = parseGoogleAdsQueryParams(query);

  const reviewsColLeft = reviews.slice(0, Math.ceil(reviews.length / 2));
  const reviewsColRight = reviews.slice(Math.ceil(reviews.length / 2));

  if (reflioReferralCode.isPresent) {
    if (!hasReflioCookieSet && reflioReferralCode.isValid) {
      try {
        const referralRecord = await ReflioClient.recordReferral(
          reflioReferralCode.value,
        );
        setReflioCookie(res, referralRecord.referral_details);
      } catch (e) {
        console.error('[Home page] Could not set Reflio cookie. Reason:\n', e);
      }
    }

    const localePart = locale === 'en' ? '' : `${locale}/`;
    return {
      redirect: {
        permanent: false,
        destination: `/${localePart}`,
      },
    };
  }

  setGoogleAdsCookies(res, maybeGoogleAdsParams);

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'header',
        'home',
        'reviews',
        'join_us',
        'footer',
      ])),
      reviewsColLeft,
      reviewsColRight,
    },
  };
}
